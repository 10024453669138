import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const Styles = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin: 0 20px;

  .title {
    color: #000033;
    font-family: Barlow Condensed;
    font-size: 2.5em;
  }

  .section-title {
    margin: 30px 0 10px 0;
    font-weight: bold;
    font-size: 22px;
  }

  .sub-title {
    margin: 30px 0 10px 0;
    font-weight: bold;
    font-size: 26px;
    text-decoration: underline;
  }

  .paragragh {
    margin-bottom: 10px;
  }

  .update-date {
    margin: 2.5rem 0;
  }

  ul {
    list-style: disc;
    padding-left: 15px;
    margin-left: 15px;
  }
`

export default class Page extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <Layout margin={"50px"}>
        <SEO title="Refund Policy" />
        <Styles>
          <Container>
            <div className="title">EVENT RESCHEDULE / CANCELLATION POLICY</div>
            <p class="paragragh">
              Tracks and promoters manage their individual policies for Event
              Rescheduling and Event Cancellations.
            </p>
            <p class="paragragh">
              If an event is postponed, your ticket may only be good for the
              rescheduled date. If an event is cancelled, your ticket may be
              good for the next comparable event scheduled, or you could be
              issued a credit or refund.
            </p>
            <p class="paragragh">
              Event Promoters manage this process, so please watch for
              communication from the track or promoter via Push Notification, in
              the “Notifications” section in the app or via Text Message.
            </p>
            <p class="paragragh">
              Always check the track’s rescheduling/cancellation policy in
              advance. You can usually find the track’s policy in the track’s
              FAQ section in the app or on their website.
            </p>
            <p class="paragragh">
              Sometimes rescheduling an event is complicated, and it may take
              time to finalize a new date. You will be notified when details
              become available.
            </p>

            <div className="title">REFUND POLICY</div>
            <p class="paragragh">
              Purchases in the Kart Pass App are NON REFUNDABLE.
            </p>
            <p class="paragragh">
              When you make purchases in the Kart Pass App, you are purchasing
              directly from the track or promoter hosting the event; Kart Pass
              is just the technology that makes it possible.
            </p>
            <p class="paragragh">
              Each track / promoter sets their own refund and credit policy and
              is responsible for issuing refunds, credits or exchanges. Pit Pay,
              Inc. does not facilitate refunds when events are cancelled.
            </p>
            <p class="paragragh">
              Tracks and promoters manage their process, so please watch for
              communication from the track or promoter via Push Notification, in
              the “Notifications” section in the app or via Text Message.
            </p>
            <p class="paragragh">
              When a refund is issued you will receive an email confirmation.
              Please allow 7-10 days for funds to be applied back to the payment
              method you used to make the purchase.
            </p>
            <p class="paragragh">
              Always check the track’s refund policy in advance. You can usually
              find the track’s refund policy in the track’s FAQ section in the
              app or on their website.
            </p>
            <p class="paragragh">
              Reach out directly to the Track or Promoter with questions.
            </p>
            <p class="paragragh">Kart Pass fees are non-refundable.</p>

            <div className="title">REFUND REQUESTS</div>
            <p class="paragragh">
              You are able Request a Refund in Payments Section in the app by
              selecting the event and tickets that you are requesting a refund
              for. This request goes directly to the Event Promoter, and they
              will have the ability to respond and honor that request, in
              accordance with their refund / credit policy or take into
              consideration special circumstances around the purchase or event.
            </p>
            <p class="paragragh">
              Requests must be submitted prior to the conclusion of the
              scheduled event. Please allow 3 business days for a response.
            </p>
            <p class="paragragh">
              For more on how this process works, visit{" "}
              <a href="https://pitpay.help/hlg">https://pitpay.help/hlg.</a>
            </p>

            <div className="title">EVENT CREDITS</div>
            <p class="paragragh">
              Event Promoters have the option and ability to issue a credit for
              your canceled or postponed event. When you are issued a credit,
              you will be notified via email and/or push notification / text
              message.
            </p>
            <p class="paragragh">
              Credits will appear in the Payments Section of the App.
            </p>
            <p class="paragragh">
              Credits are ONLY good for events that the original Event Promoter
              promotes. (Sometimes the Promoter is not the same as the regular
              Track Operator or Promoter at the track that hosted the event that
              you may have been issued credit from.)
            </p>
            <p class="paragragh">
              You can check the events where you can use your credit in the
              Payment Section of the App under Credits.
            </p>
            <p class="paragragh">
              When you go to purchase tickets for an event where your credit is
              applicable, you will be reminded that you have a credit, and will
              be able to apply your credit to the purchase at check out.
            </p>
            <p class="paragragh">
              Tracks and Promoters manage their process, so please watch for
              communication from them.
            </p>

            <p class="update-date">Updated 01.04.23</p>
          </Container>
        </Styles>
      </Layout>
    )
  }
}
